<template>
	<v-container fluid>
		<v-row>
			<v-col
				class="pa-3 pa-md-5"
				cols="12"
			>
				<AdminItemsTableTopbar
					:check-remove-model="checkRemoveModel"
					:desserts-length="tagItems.length"
					hide="노출순서"
					@callCreate="onCreateItem()"
					@callConfirmRemove="confirmRemove = true"
				/>
			</v-col>
			<v-col cols="6">
				<v-col class="pa-3 pt-0 pa-md-5">
					<v-col class="pa-0">
						<h3>태그관리</h3>
					</v-col>
					<CommonDataTableGridType02
						v-model="tagModel"
						:headers="tagHeader"
						:desserts="tagItems"
						height="600px"
						:loading="loading"
						@open="onReadItem"
						@edit="onEditItem"
					/>
				</v-col>
				<CommonConfirm
					:dialog="confirmRemove"
					:title="`${common_title} 삭제`"
					:text="`선택하신 목록이 모두 삭제 됩니다. <br/> 그래도 삭제하시겠습니까?`"
					@close="confirmRemove = false"
					@submit="removeData"
				/>
			</v-col>
			<v-col cols="6">
				<v-col class="pa-3 pt-0 pa-md-5">
					<v-col class="pa-0">
						<AdminItemsTableTopbar02
							:desserts-length="recommendItems.length"
							:hide="['등록', '삭제']"
							title="추천 태그"
							@callManage="onManageItem('recommend')"
							@callOpenOrder="openOrderPopup('recommend')"
						/>
					</v-col>
					<CommonDataTableGridType02
						:headers="recommendHeader"
						:desserts="recommendItems"
						height="250px"
						:loading="loading"
					/>
					<v-col class="pa-0 mt-5">
						<AdminItemsTableTopbar02
							:desserts-length="trendItems.length"
							:hide="['등록', '삭제']"
							title="주목받는 트렌드"
							@callManage="onManageItem('main')"
							@callOpenOrder="openOrderPopup('main')"
						/>
					</v-col>
					<CommonDataTableGridType02
						:headers="trendHeader"
						:desserts="trendItems"
						height="250px"
						:loading="loading"
					/>
				</v-col>
			</v-col>
		</v-row>
		<!-- tag -->
		<AdminProductTagCreate
			v-if="createDialog"
			v-model="createModel"
			:title="common_title"
			:dialog="createDialog"
			:mode="mode"
			:edit-item="editItem"
			@close="closeDialog"
		/>
		<AdminProductTagRead
			v-if="readMode"
			:title="common_title"
			:dialog="readMode"
			:read-item="readItem"
			@close="closeDialog"
		/>
		<AdminProductTagManage
			v-if="manageMode"
			:title="manageTitle"
			:dialog="manageMode"
			:desserts="manageItem"
			:headers="trendHeader"
			:max-length="manageLength"
			:type="manageType"
			:checked-data="checkedData"
			@close="closeDialog"
		/>
		<CommonDataTableGridTypeDrag01
			v-if="openOrder"
			v-model="orderItems"
			:show-all="true"
			:title="orderTitle"
			:dialog="openOrder"
			:headers="orderHeader"
			:desserts="orderItems"
			@close="openOrder = false"
			@submit="onApiCallOrder"
		/>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminProductTag',
	mixins: [common, admin],
	data: () => ({
		mode: 'read',
		readMode: false,
		desserts: [],
		readItem: {},
		editItem: {},
		createModel: {},
		createDialog: false,
		confirmRemove: false,
		tagGroupModel: [],
		tagGroupHeader: [
			{ text: '태그 그룹', value: 'code_name', width: '100px' },
			{ text: '보기', value: 'data-read', width: '30px', sortable: false },
		],
		tagModel: [],
		tagHeader: [
			{ text: '태그명', value: 'tag_value', width: '100px' },
			{ text: '이미지', value: 'image', width: '100px' },
			{ text: '등록자', value: 'regi_userid', width: '100px' },
			{ text: '등록일', value: 'regi_date', width: '100px' },
			{ text: '보기', value: 'data-read', width: '30px', sortable: false },
			{ text: '수정', value: 'data-edit', width: '30px', sortable: false },
		],
		recommendHeader: [
			{ text: '태그명', value: 'tag_value', width: '60px' },
			{ text: '등록자', value: 'regi_userid', width: '50px' },
			{ text: '등록일', value: 'regi_date', width: '50px' },
		],
		trendHeader: [
			{ text: '태그명', value: 'tag_value', width: '60px' },
			{ text: '이미지', value: 'image', width: '60px' },
			{ text: '등록자', value: 'regi_userid', width: '50px' },
			{ text: '등록일', value: 'regi_date', width: '50px' },
		],
		orderHeader: [
			{ name: '순서', value: 'priority', width: '80' },
			{ name: '태그명', value: 'tag_value', width: '60' },
			{ name: '이미지', value: 'image', width: '50' },
			{ name: '', value: 'hendle-icon', width: '20' },
		],
		models: {
			keyword: null,
		},
		showDate: null,
		loading: false,
		openOrder: false,
		orderTitle: null,
		orderItems: [],
		manageMode: false,
		manageTitle: null,
		manageLength: null,
		manageType: null,
		checkedData: [],
		manageItem: [],
	}),
	computed: {
		...mapGetters(['AUTH_GET_ADMIN_USER', 'ADMIN_GET_PRODUCT_TAG_GROUP_ITEMS', 'ADMIN_GET_PRODUCT_TAG_ITEMS']),
		checkRemoveModel() {
			console.log('this.tagModel', this.tagModel)
			return this.tagModel !== null ? this.tagModel.length > 0 : false
		},
		tagItems() {
			let tagData = this.tagGroupModel
			return tagData
		},
		recommendItems() {
			let recommendData = this.tagItems.filter(e => e.recommend_yn === 'Y')
			recommendData.forEach(x => (x.priority = x.recommend_priority))
			return recommendData.sort((a, b) => {
				if (a.recommend_yn < b.recommend_yn) return 1
				if (a.recommend_yn > b.recommend_yn) return -1
				if (parseInt(a.recommend_priority) < parseInt(b.recommend_priority)) return -1
				if (parseInt(a.recommend_priority) > parseInt(b.recommend_priority)) return 1
				if (parseInt(a.id) < parseInt(b.id)) return -1
				if (parseInt(a.id) > parseInt(b.id)) return 1
			})
		},
		trendItems() {
			let trendItems = this.tagItems.filter(e => e.main_yn === 'Y')
			trendItems.forEach(x => (x.priority = x.main_priority))
			return trendItems.sort((a, b) => {
				if (a.main_yn < b.main_yn) return 1
				if (a.main_yn > b.main_yn) return -1
				if (parseInt(a.main_priority) < parseInt(b.main_priority)) return -1
				if (parseInt(a.main_priority) > parseInt(b.main_priority)) return 1
				if (parseInt(a.id) < parseInt(b.id)) return -1
				if (parseInt(a.id) > parseInt(b.id)) return 1
			})
		},
	},
	watch: {},
	async created() {
		await this.actApiCall({ method: 'search' })
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapMutations(['ADMIN_MU_PRODUCT_TAG_ITEMS']),
		...mapActions(['ADMIN_ACT_PRODUCT_TAG_ITEMS']),
		async actApiCall(params) {
			this.loading = true
			await this.ADMIN_ACT_PRODUCT_TAG_ITEMS(params).then(res => {
				if (res) this.tagGroupModel = res
				this.loading = false
			})
		},
		openOrderPopup(type) {
			if (type === 'main') {
				this.orderItems = this.trendItems.sort((a, b) => a.main_priority - b.main_priority)
				this.orderTitle = '주목받는 트렌드'
			} else {
				this.orderItems = this.recommendItems.sort((a, b) => a.recommend_priority - b.recommend_priority)
				this.orderTitle = '추천태그'
			}
			this.openOrder = true
		},
		async searchItems() {
			let startDate
			let endDate

			if (this.showDate !== null) {
				startDate = this.common_dateFilter(this.showDate[0])
				endDate = this.showDate[1] === undefined ? startDate : this.common_dateFilter(this.showDate[1])
			} else {
				startDate = null
				endDate = null
			}

			const params = {
				search_keyword: this.models.keyword,
				modify_date_s: startDate,
				modify_date_e: endDate,
			}

			console.log('search', params)

			const item = {
				method: 'search',
				params: params,
			}

			await this.actApiCall(item)
		},
		onGroupReadItem(item) {
			console.log('onGroupReadItem', item)
			this.readItem = item
		},
		onCreateItem(item) {
			this.mode = 'create'
			console.log('onCreateItem', item)
			this.editItem = {
				tag_value: null,
				image: null,
			}
			this.createDialog = true
		},
		onReadItem(item) {
			this.readItem = item
			this.readMode = true
		},
		onEditItem(item) {
			this.editItem = item
			this.mode = 'edit'
			this.createDialog = true
		},
		onManageItem(type) {
			this.manageType = type
			if (type === 'main') {
				this.manageLength = 6
				this.manageTitle = '주목받는 트렌드 관리'
				this.checkedData = this.trendItems
				this.manageItem = JSON.parse(JSON.stringify(this.tagItems.filter(e => e.image)))
			} else {
				this.manageLength = 9
				this.manageTitle = '추천 태그 관리'
				this.checkedData = this.recommendItems
				this.manageItem = JSON.parse(JSON.stringify(this.tagItems))
			}
			this.manageMode = true
		},
		async removeData() {
			const params = this.tagModel.map(item => {
				return { tag_id: parseInt(item.tag_id) }
			})
			await this.actApiCall({ method: 'delete', params: params })
			await this.actApiCall({ method: 'search' })
			this.confirmRemove = false
			this.tagModel = []
		},
		async onApiCallOrder(val) {
			console.log('onApiCallOrder')
			// return
			const orderList = []
			const key = this.orderTitle === '추천태그' ? 'recommend' : 'main'
			for (let i of val) {
				let temp = { tag_id: i.id }
				temp[`${key}_priority`] = i.priority
				orderList.push(temp)
			}
			console.log('onApiCallOrder', orderList)

			const items = { method: `${key}Priority`, params: orderList }
			await this.ADMIN_ACT_PRODUCT_TAG_ITEMS(items)
			await this.actApiCall({ method: 'search' })
		},
		async closeDialog() {
			this.readMode = false
			this.createDialog = false
			this.manageMode = false
			await this.actApiCall({ method: 'search' })
		},
		updateData() {
			this.ADMIN_MU_PRODUCT_TAG_ITEMS([])
			this.actApiCall({ method: 'getItems' })
		},
	},
}
</script>
